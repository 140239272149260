import React from "react";
import {Helmet} from "react-helmet";

import ResearchAndDevelopmentCombinationsDiagram
    from "../../components/research-and-development/ResearchAndDevelopmentCombinationsDiagram";

import * as classes from "./biochip-gyn.module.scss";

export default function ResearchAndDevelopmentLiquidBiochipPage() {
    return (
        <article>
            <Helmet>
                <title>Биочип GYN</title>
            </Helmet>
            <h2>Биочип GYN</h2>
            <p>Стадия: Подготовка регистрационных документов</p>
            <p><strong>Панель антител — P16, Ki67</strong></p>
            <h3>Назначение</h3>
            <p>Уточнение степени интраэпителиального поражения плоского эпителия (рак шейки матки, рак головы и шеи).</p>
            <h3>P16</h3>
            <p>Клеточный протеин, участвующий в регуляции клеточного цикла и являющийся ингибитором активности циклинзависимой киназы CDK4/6. В норме киназа неактивна и клеточная пролиферация инактивирована за счет наличия прочной связи фактора транскрипции E2F с белком-супрессором рRb. Белок р16 осуществляет контроль разобщения комплекса E2F-Rb, не допуская пролиферации клетки. В нормально функционирующих клетках синтез р16 сдерживается по механизму обратной связи и, следовательно, концентрация данного белка в нормальной клетке чрезвычайно мала, что проявляется негативной иммуноцитохимической реакцией. Онкобелок Е7 ВПЧ с высоким онкогенным риском посредством внутриклеточных механизмов приводит к разобщению комплекса E2F-рRb, активации CDK4/6 киназы, а впоследствии стимулирует чрезмерную клеточную пролиферацию.</p>
            <h3>Ki67</h3>
            <p>Антиген Ki67 является ядерным негистоновым белком, который присутствует на всех стадиях клеточного цикла кроме G0. В целом, Ki67 — хороший маркер пролиферации клеточных популяций. Индекс окраски антителом к Ki67 — надежный прогностический маркер для многочисленных неоплазий, включая астроцитомы 2 степени, олигодендроглиомы, карциномы толстого кишечника и молочной железы.</p>
            <ResearchAndDevelopmentCombinationsDiagram>
                <ResearchAndDevelopmentCombinationsDiagram.Heading>
                    Варианты комбинаций — P16, Ki67
                </ResearchAndDevelopmentCombinationsDiagram.Heading>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    P16 +<br />
                    Ki67 -
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description>
                        Негативная реакция (наличие персистенции ВПЧ, без нарушения клеточной пролиферации)
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    P16 -<br />
                    Ki67 +
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description>
                        Негативная реакция (повышенная пролиферация клеток в результате реактивных процессов)
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    P16 +<br />
                    Ki67 +
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description>
                        Негативная реакция (повышенная пролиферация клеток в результате реактивных процессов)
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                    <p className={classes.combinationAdditional1}>
                        <span>LSIL</span> <span>HSIL</span>
                    </p>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                <ResearchAndDevelopmentCombinationsDiagram.Definition>
                    P16 -<br />
                    Ki67 -
                </ResearchAndDevelopmentCombinationsDiagram.Definition>
                <ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
                    <ResearchAndDevelopmentCombinationsDiagram.Description>
                        Негативная реакция
                    </ResearchAndDevelopmentCombinationsDiagram.Description>
                </ResearchAndDevelopmentCombinationsDiagram.DescriptionContainer>
            </ResearchAndDevelopmentCombinationsDiagram>
        </article>
    );
}
